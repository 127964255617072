import { ref, onMounted } from "vue";

const roomMapping = ref([]);
const filteredRooms = ref([]);
const isLoading = ref(false);
const isLoaded = ref(false);
const error = ref(null);

export function useRoomMapping() {
  const fetchRoomMappings = async () => {
    if (isLoaded.value || isLoading.value) {
      console.log("[RoomMapping] Fetch skipped: already loaded or loading");
      return;
    }

    isLoading.value = true;
    error.value = null;

    try {
      console.log("[RoomMapping] Fetching room mappings...");
      const response = await fetch("/api/getRoomMapping", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch room mappings");
      }

      const data = await response.json();

      if (!data.success) {
        throw new Error(data.message || "Error in room mappings response");
      }

      roomMapping.value = data.data; // All room mappings
      filteredRooms.value = data.filteredRooms; // Filtered room mappings
      isLoaded.value = true; // Mark as loaded
      console.log("[RoomMapping] Fetch successful");
    } catch (err) {
      console.error("[Error] Fetching room mappings:", err);
      error.value = err.message;
    } finally {
      isLoading.value = false;
    }
  };

  onMounted(() => {
    fetchRoomMappings();
  });

  return {
    roomMapping,
    filteredRooms,
    isLoading,
    error,
    fetchRoomMappings,
  };
}
